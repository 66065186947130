import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CareerPage from "../components/career/prism"


const EmploymentApplication = () => {
  return (
    <>
      {/* <Layout>
        <SEO title="Employment Application" />
        <div className="employment-app-main">
          <div className="container">
            <h1 className="text-center mb-5">Employment Application</h1>
            <iframe
              className="gray-hawk-application"
              src="https://forms.zohopublic.com/virtualoffice9340/form/ApplicationForm/formperma/P2X2VHmDaA9NUIX1xYmRS2QaoVWMf6yDaGaByLwJLh4"
              frameborder="0"
              title="Gray hawk Application Form"
              width="100%"
              style={{ height: "100rem" }}
              frameBorder="0"
            ></iframe>
          </div>
        </div>
      </Layout> */}
      <Layout>
        <SEO title="Career page" />
        <div className="thank-you-wrapper valueprop section">
          <div className="container">
            {/* <CareerPage /> */}
            <div class="container">
           
               <iframe
              className="gray-hawk-application responsive-iframe"
              src="https://grayhawklandsolutions.applytojob.com/apply"
              frameborder="0"
              title="Gray hawk Application Form"
              width="100%"
              style={{ height: "40rem", background: "#fff" }}
              frameBorder="0"
            ></iframe>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default EmploymentApplication
